import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const Styles = styled.div`
    .blog-page {
        margin-top: 3.125rem;
        padding: 0 1.25rem;

        .gatsby-image-wrapper {
            width: 100%!important;
            max-height: 25rem;
            margin: 1.25rem 0;
        }
    }

    .container {
        display: inline-block;
    }

    h1, h2, h3, h4, h5, h6, p, ul, ol {
        margin: 0 0 1rem;
    }

    figure {
        margin: 1rem 0;

        img {
            width: 100%;
        }
    }
    

    a {
        color: black;
    }

    ul, ol {
        padding-inline-start: 40px;
    }



    @media only screen and (min-width: 1024px) {

        .blog-page {
            padding: 0;
         }

         .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }

        .gatsby-image-wrapper {
            max-height: 33rem;
        }

    }
`;

export default function singleBlogPage({data}) {
	const {blog} = data;
	const portableTextContent = blog._rawCopy;
	const serializers = {
        marks: {
          link: ({mark, children}) => {
            const { href } = mark;
            return <a href={href} target="_blank" rel="noopener">{children}</a>;
          },
        }
    };

    return(
        <Styles>
            <div className="pad">
                <section className="blog-page">
                    <div className="container">
                        <div className="col-1 hidden-m"/>
                        <div className="col-10">
                            <h1 className="title">{blog.title}</h1>
                            <Img fixed={blog.image.asset.fixed}/>
                            <BlockContent
                                blocks={portableTextContent}
                                imageOptions={{ fit: 'max'}}
                                projectId="gyyqcxot"
                                dataset="production"
                                serializers={serializers}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </Styles>
    );
}

export const query = graphql`
	query($slug: String!) {
		blog: sanityBlogPost( slug: { current: { eq: $slug } }) {
			id
			titleSEO
			descriptionSEO
			keywordsSEO
			slug {
				current
			}
			title
			_rawCopy
			alt
			image {
				asset {
					fixed(width: 1400) {
						...GatsbySanityImageFixed
					}
				}
			}
		}
		settings: sanitySiteSettings(_id: {eq: "LWHTsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
					fixed(height: 400, width: 400) {
						base64
						srcWebp
						srcSetWebp
					}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		regions: allSanityLwhTregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityLwhTtheme {
			nodes {
				name
				slug {
					current
				}
				showInNav
			}
		}
	}
`;